<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    :fill="modalIconColor"
  >
    <title>{{ tr('My Profile') }}</title>
    <path d="M256.1 288c79.5 0 144-64.5 144-144S335.6 0 256.1 0s-144 64.5-144 144 64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96zm89.6 256c-11 0-21.8 2.6-32.2 6.2-18 6.3-37.3 9.8-57.4 9.8s-39.4-3.5-57.4-9.8c-10.4-3.6-21.2-6.2-32.2-6.2-74.2 0-134.4 60.2-134.4 134.4V488c0 13.3 10.7 24 24 24s24-10.7 24-24v-49.6c0-45.5 35.4-82.4 80-85.8v50c-23.1 6.9-40 28.1-40 53.4 0 30.9 25.1 56 56 56s56-25.1 56-56c0-25.3-16.9-46.5-40-53.4v-44.7c20.8 6.3 42.3 10.1 64 10.1 21.8 0 43.2-3.8 64-10.1v36.3c-28.2 7.5-48 34.5-48 64.6V488c0 4.2 1.7 8.3 4.7 11.3l10.3 10.3c3.1 3.1 8.2 3.1 11.3 0l11.3-11.3c3.1-3.1 3.1-8.2 0-11.3l-5.7-5.7V456c0-19.4 17.4-34.8 37.4-31.6 15.7 2.6 26.6 17.4 26.6 33.3v23.6l-5.7 5.7c-3.1 3.1-3.1 8.2 0 11.3l11.3 11.3c3.1 3.1 8.2 3.1 11.3 0l10.3-10.3c3-3 4.7-7.1 4.7-11.3v-32c0-29.7-20.5-54.5-48-61.6v-41.7c44.6 3.3 80 40.3 80 85.8V488c0 13.3 10.7 24 24 24s24-10.7 24-24v-49.6c.2-74.2-60-134.4-134.2-134.4zM200.1 456c0 13.3-10.7 24-24 24s-24-10.7-24-24 10.7-24 24-24 24 10.7 24 24z" />
  </svg>
</template>

<script>
// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, inject } from 'vue'
import { useStore } from 'vuex'

import { themeColors } from '@/common/shared.js'

export default {
  setup() {
    const store = useStore()

    const modalIconColor = computed(() => {
      return themeColors[store.getters.currentThemeName].baseHeaderIconColor
    })

    const tr = inject('tr')

    return {
      modalIconColor,
      tr,
    }
  },
}
</script>
