// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { KJUR } from 'jsrsasign'
import { EmitterMessage } from 'emitter-io'

import { defaultSessionTools, initToolsPayload } from './initTools'
import { defaultThemeName } from '../../../common/shared'

const toTitleCase = (s) => s.replace(/\b[a-z]/g, (x) => x.toUpperCase())

const getDisplayName = (claims) => {
  if (claims.given_name) { return claims.given_name }
  if (claims.name) { return claims.name }
  if (claims.preferred_username) { return toTitleCase(claims.preferred_username) }
  if (claims.email) {
    const i = claims.email.indexOf('@')
    if (i > 0) { return toTitleCase(claims.email.substring(0, i)) }
    return toTitleCase(claims.email)
  }
  return ''
}

const createNewProfile = (claims) => {
  const id = claims.sub
  const displayName = getDisplayName(claims)
  return {
    id,
    displayName,
    isUniqueDisplayName: true,
    currentThemeName: defaultThemeName(),
    lightThemeName: defaultThemeName(),
    darkThemeName: 'Midnight',
    timeZone: 'America/Los_Angeles',
    language: 'en_US.UTF-8',
    confirmBeforeDeletingSession: false,
    sessions: {
      default: {
        id: 'default',
        name: 'default',
        ownerId: id,
        ownerName: displayName,
        locallyAnonymized: false,
        patient: '',
        lastAccessed: 0,
        collaborateWithAnyoneLink: false,
        collaborators: [],
        viewerWithAnyoneLink: false,
        viewers: [],
        toolCount: 0,
      },
    },
  }
}

const createDefaultSession = (userId, patient) => {
  return {
    id: 'default',
    ownerId: userId,
    locallyAnonymized: false,
    collaborateWithAnyoneLink: false,
    collaborators: [], // user IDs
    viewerWithAnyoneLink: false,
    viewers: [], // user IDs
    patient: patient || '',
    tools: defaultSessionTools,
  }
}

export const noBackendSetup = (app) => {
  let messageSendFunc = () => { }
  let userId = ''

  const rpcPrivateChannelKey = 'GLIOOSV2ADRGSM3MWOEY42JYXY'
  const rpcPrivateChannel = `v1/rpc/${rpcPrivateChannelKey}`

  const publishToKey = () => {
    return ({ channel, message }) => {
      console.log(`pubsub publish("${channel}"): ${message.length} bytes`)
      if (channel === `${rpcPrivateChannel}/onLogin`) {
        const sJWT = JSON.parse(message).authResponse.access_token
        // const header = Buffer.from(sJWT.split(".")[0], 'base64').toString()
        // const headerObj = KJUR.jws.JWS.readSafeJSONString(header)
        const payload = Buffer.from(sJWT.split('.')[1], 'base64').toString()
        const claims = KJUR.jws.JWS.readSafeJSONString(payload)
        userId = claims.sub
        const profileJSON = JSON.stringify(createNewProfile(claims))
        const channel = `${rpcPrivateChannel}/profile/`
        const msg = new EmitterMessage({ topic: channel, payload: profileJSON })
        messageSendFunc(msg)
        return
      }

      if (channel === `${rpcPrivateChannel}/getSavedSession`) {
        const channel = `v1/sessions/${userId}/default/all/`
        const patient = JSON.parse(message).patient
        const sessionJSON = JSON.stringify(createDefaultSession(userId, patient))
        const msg = new EmitterMessage({ topic: channel, payload: sessionJSON })
        messageSendFunc(msg)
        return
      }

      if (channel === `${rpcPrivateChannel}/requestAllTools`) {
        const channel = `${rpcPrivateChannel}/allToolsResponse/`
        const allToolsJSON = JSON.stringify(initToolsPayload)
        const msg = new EmitterMessage({ topic: channel, payload: allToolsJSON })
        messageSendFunc(msg)
        return
      }

      if (channel === `${rpcPrivateChannel}/requestSessionTemplates`) { return }  // ignore
      // if (channel.endsWith('/default/tools')) { console.log(message) }  // useful for creating new default session template layouts.
      if (channel.startsWith('v1/sessions/')) { return } // ignore
      if (channel.startsWith('v1/users/')) { return } // ignore

      console.log(`UNHANDLED MESSAGE: ${message}`)
    }
  }
  app.provide('publishToKey', publishToKey)

  const client = {
    keygen: () => { },
    on: (e, f) => {
      // console.log(`noBackendSetup: e=${e}`)
      if (e === 'message') {
        messageSendFunc = f
        return
      }
      if (e === 'keygen') {
        f({
          status: 200,
          key: 'unused',
          channel: `${rpcPrivateChannel}/#/`,
        })
        return
      }
    },
    subscribe: ({ channel }) => {
      // console.log(`noBackendSetup: subscribe(key, channel=${channel})`)
      const userChannelPrefix = 'v1/users/'
      if (channel.startsWith(userChannelPrefix)) {
        userId = channel.substring(userChannelPrefix.length, channel.length - 1)
        // console.log(`userId=${userId}`)
      }
    },
  }
  // console.log(messageSendFunc)

  return { client, publishToKey }
}
