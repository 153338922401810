<template>
  <div class="system-toast" v-if="showSystemToast" :style="systemToastStyle">
    {{ showSystemToast }}
  </div>
  <router-view :key="$route.fullPath" />
</template>

<script>
// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, watch, watchEffect } from 'vue'
import { useStore } from 'vuex'

import { themeColors } from '@/common/shared.js'

export default {
  setup() {
    const store = useStore()

    const setBodyBackgroundColor = (name) => {
      const body = document.querySelector('body')
      const curColors = themeColors[name]
      body.style.setProperty('--alternatingTableRowsBackgroundColor1', curColors.alternatingTableRowsBackgroundColor1)
      body.style.setProperty('--alternatingTableRowsBackgroundColor2', curColors.alternatingTableRowsBackgroundColor2)
      body.style.setProperty('--tableBorderColor', curColors.tableBorderColor)
      body.style.setProperty('--tableCellSortLinkColor', curColors.tableCellSortLinkColor)
      body.style.setProperty('--tableCellTextColor', curColors.tableCellTextColor)
      body.style.setProperty('--tableCellTextLinkColor', curColors.tableCellTextLinkColor)
      body.style.setProperty('--tableHeaderCellColor', curColors.tableHeaderCellColor)
      body.style.setProperty('--tableHeaderTextColor', curColors.tableHeaderTextColor)
      body.style.setProperty('--tableLineColor', curColors.tableLineColor)
      body.style.setProperty('--toolBackgroundColor', curColors.toolBackgroundColor)

      body.style.setProperty('--toolFieldBackgroundColor', curColors.toolFieldBackgroundColor)
      body.style.setProperty('--toolFieldTextColor', curColors.toolFieldTextColor)
      body.style.setProperty('--toolIconColor', curColors.toolIconColor)
      body.style.setProperty('--toolInputBackgroundColor', curColors.toolInputBackgroundColor)
      body.style.setProperty('--toolInputTextColor', curColors.toolInputTextColor)
      body.style.setProperty('--toolPulldownBackgroundColor', curColors.toolPulldownBackgroundColor)
      body.style.setProperty('--toolPulldownTextColor', curColors.toolPulldownTextColor)
      body.style.setProperty('--toolScrollArrowsColor', curColors.toolScrollArrowsColor)
      body.style.setProperty('--toolScrollBarBackgroundColor', curColors.toolScrollBarBackgroundColor)
      body.style.setProperty('--toolScrollBarColor', curColors.toolScrollBarColor)

      body.style.setProperty('--toolTextColor', curColors.toolTextColor)
      body.style.setProperty('--accentColor', curColors.accentColor)
      body.style.setProperty('--alternatingTableRowsBackgroundColor', curColors.alternatingTableRowsBackgroundColor)
      body.style.setProperty('--appScrollBarBackgroundColor', curColors.appScrollBarBackgroundColor)
      body.style.setProperty('--appScrollBarColor', curColors.appScrollBarColor)
      body.style.setProperty('--baseBackgroundColor', curColors.baseBackgroundColor)
      body.style.setProperty('--baseHeaderBackgroundColor', curColors.baseHeaderBackgroundColor)
      body.style.setProperty('--baseHeaderIconColor', curColors.baseHeaderIconColor)
      body.style.setProperty('--baseHeaderTextColor', curColors.baseHeaderTextColor)
      body.style.setProperty('--buttonBackgroundColor', curColors.buttonBackgroundColor)

      body.style.setProperty('--buttonBorderColor', curColors.buttonBorderColor)
      body.style.setProperty('--buttonTextColor', curColors.buttonTextColor)
      body.style.setProperty('--secondaryButtonBackgroundColor', curColors.secondaryButtonBackgroundColor)
      body.style.setProperty('--secondaryButtonBorderColor', curColors.secondaryButtonBorderColor)
      body.style.setProperty('--confirmationPopupBackgroundColor', curColors.confirmationPopupBackgroundColor)
      body.style.setProperty('--errorBackgroundColor', curColors.errorBackgroundColor)
      body.style.setProperty('--errorTextColor', curColors.errorTextColor)
      body.style.setProperty('--inputElementBorderColor', curColors.inputElementBorderColor)
      body.style.setProperty('--secondaryButtonTextColor', curColors.secondaryButtonTextColor)
      body.style.setProperty('--confirmationPopupTextColor', curColors.confirmationPopupTextColor)

      body.style.setProperty('--modalBackgroundColor', curColors.modalBackgroundColor)
      body.style.setProperty('--modalIconColor', curColors.modalIconColor)
      body.style.setProperty('--modalTextColor', curColors.modalTextColor)
      body.style.setProperty('--plotColor01', curColors.plotColor01)
      body.style.setProperty('--plotColor02', curColors.plotColor02)
      body.style.setProperty('--plotColor03', curColors.plotColor03)
      body.style.setProperty('--plotColor04', curColors.plotColor04)
      body.style.setProperty('--productLogoColor', curColors.productLogoColor)
      body.style.setProperty('--spinnerStrokeColor', curColors.spinnerStrokeColor)
      body.style.setProperty('--tableRowHoverBackgroundColor', curColors.tableRowHoverBackgroundColor)

      body.style.setProperty('--toolBorderColor', curColors.toolBorderColor)
      body.style.setProperty('--toolDragTargetColor', curColors.toolDragTargetColor)
      body.style.setProperty('--toolDragTargetOutlineColor', curColors.toolDragTargetOutlineColor)
      body.style.setProperty('--toolHeaderBackgroundColor', curColors.toolHeaderBackgroundColor)
      body.style.setProperty('--toolHeaderTextColor', curColors.toolHeaderTextColor)
      body.style.setProperty('--generalDescriptorTextColor', curColors.generalDescriptorTextColor)

      body.style.setProperty('--Grey100', curColors.Grey100)
      body.style.setProperty('--Grey200', curColors.Grey200)
      body.style.setProperty('--Grey300', curColors.Grey300)
      body.style.setProperty('--Grey400', curColors.Grey400)
      body.style.setProperty('--Grey500', curColors.Grey500)
      body.style.setProperty('--Grey600', curColors.Grey600)
      body.style.setProperty('--Grey700', curColors.Grey700)
      body.style.setProperty('--Grey800', curColors.Grey800)
      body.style.setProperty('--Grey900', curColors.Grey900)
      body.style.setProperty('--Primary', curColors.Primary)
      body.style.setProperty('--PrimaryLight', curColors.PrimaryLight)
      body.style.setProperty('--PrimaryDark', curColors.PrimaryDark)
      body.style.setProperty('--Secondary', curColors.Secondary)
      body.style.setProperty('--SecondaryLight', curColors.SecondaryLight)
      body.style.setProperty('--SecondaryDark', curColors.SecondaryDark)
      body.style.setProperty('--Tertiary', curColors.Tertiary)
      body.style.setProperty('--TertiaryLight', curColors.TertiaryLight)
      body.style.setProperty('--TertiaryDark', curColors.TertiaryDark)
      body.style.setProperty('--SuccessGreenDark', curColors.SuccessGreenDark)
      body.style.setProperty('--SuccessGreenLight', curColors.SuccessGreenLight)
      body.style.setProperty('--WarningYellowDark', curColors.WarningYellowDark)
      body.style.setProperty('--WarningYellowLight', curColors.WarningYellowLight)
      body.style.setProperty('--ErrorRedDark', curColors.ErrorRedDark)
      body.style.setProperty('--ErrorRedLight', curColors.ErrorRedLight)
      body.style.setProperty('--White', curColors.White)
      body.style.setProperty('--Black', curColors.Black)
      body.style.setProperty('--MarketingMagenta', curColors.MarketingMagenta)
      body.style.setProperty('--MarketingOrange', curColors.MarketingOrange)
      body.style.setProperty('--BlendedOrange', curColors.BlendedOrange)
      body.style.setProperty('--datmaBase', curColors.datmaBase)
      body.style.setProperty('--datma360', curColors.datma360)
      body.style.setProperty('--datmaFed', curColors.datmaFed)
      body.style.setProperty('--datmaWhy', curColors.datmaWhy)
    }


    watchEffect(() => setBodyBackgroundColor(store.getters.currentThemeName))

    const language = computed(() => store.getters.profile.language)
    watch(language, () => {
      const bodyStyle = document.body.style
      if (language.value === 'zh_CN.UTF-8') {
        bodyStyle.fontFamily = '"Noto Sans SC", sans-serif'
      }
      else if (language.value === 'zh_TW.UTF-8') {
        bodyStyle.fontFamily = '"Noto Sans TC", sans-serif'
      }
      else {
        bodyStyle.fontFamily = '"Roboto", sans-serif'
      }
    })

    const showSystemToast = computed(() => store.getters.showSystemToast)

    return {
      showSystemToast,
    }
  },
}
</script>

<style lang="scss">
@import '@/common/tutorial.scss';
@import '@/common/scrollbar.scss';
@import '@/App.scss';

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'),
    url('./fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans SC';
  src: local('Noto Sans SC'),
    url('./fonts/NotoSansSC-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Noto Sans TC';
  src: local('Noto Sans TC'),
    url('./fonts/NotoSansTC-Regular.otf') format('opentype');
}

* {
  box-sizing: border-box;
  transition: transform 0.15s;
}

body {
  font-family: 'Roboto', Avenir, Helvetica, Arial, sans-serif;
  background-color: var(--Grey100);
  margin: 0;
  overflow-x: hidden;
  min-height: 100vh; // Required to get tutorial to work!
}

html {
  overflow-y: hidden;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--toolTextColor);
  position: relative;
}

.system-toast {
  z-index: 100;
  position: fixed;
  top: 3rem;
  left: 50%;
  margin-left: -12.5rem;
  height: 4rem;
  line-height: 4rem;
  width: 25rem;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: var(--errorBackgroundColor);
  color: var(--errorTextColor)
}
</style>
